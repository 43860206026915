.Home {
    height: 100vh;
    padding: 20px 100px;
    background: url(https://amanda-data.s3.ca-central-1.amazonaws.com/background.jpg);
    background-size: cover;
    overflow: auto;
}

@media screen and (max-width: 768px) {
    .Home {
        padding: 20px 50px;
    }
}

@media screen and (max-width: 480px) {
    .Home {
        padding: 10px 20px;
    }
}

@media screen and (max-width: 375px) {
    .Home {
        padding: 10px 10px;
    }
}

.Home .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.Home .header .Title {
    color: red;
    opacity: 0.9;
    text-align: center;
    font-size: 60px;
    font-family: Snell Roundhand, cursive;
    font-weight: 800;
}
.Card {
    margin: auto;
    margin-top: 30px;
    width: 100%;
    height: fit-content;
    background-color: rgb(255, 255, 255, 0.8);
    padding: 30px 80px;
}

@media screen and (max-width: 768px) {
    .Card {
        padding: 20px 50px;
        width: 100%;
    }
}

@media screen and (max-width: 480px) {
    .Card {
        padding: 10px 20px;
    }
}

.Card .cardContent {
    width: 100%;
    height: 100%;
    font-family: Snell Roundhand, cursive;
    font-weight: 700;
    font-size: 30px;
}

@media screen and (max-width: 768px) {
    .Card .cardContent {
        font-size: 22px;
    }
}

@media screen and (max-width: 480px) {
    .Card .cardContent {
        font-size: 20px;
    }
}
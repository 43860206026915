.Gallery {
    width: 100%;
    height: 650px;
    margin-top: 10px;
}

@media screen and (max-width: 768px) {
    .Gallery {
        height: 450px;
    }
}

@media screen and (max-width: 480px) {
    .Gallery {
        height: 350px;
    }
}

.Gallery .carousel-inner {
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.9);
}

.Gallery .carousel-inner .carousel-item {
    width: 100%;
    height: 100%;
    transition: all 0.5s ease-in-out;
}

.Gallery .carousel-inner .carousel-item img {
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    object-fit: contain;
    margin: auto;
    display: block;
}